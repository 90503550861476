@media (max-width: 767px) {
    ::-webkit-scrollbar {
        width: 0;
        display: none; 
}
 }

.calendar-container ::-webkit-scrollbar-thumb {
    height: 4px;
    width: 2px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px; 
}

::-webkit-scrollbar-track {
    background-color: none; 
}

.calendar-container {
    overflow-x: auto; 
    white-space: nowrap; 
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  